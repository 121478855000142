<template>
    <div id="instantMessage">
        <div class="ImContent">
            <el-container>
                <el-aside class="chat-left">
                    <div class="chat-left-content">
                        <ul class="list-unstyled">
                            <li class="media">
                                <div class="user-header">
                                    <div class="user-status"></div>
                                    <img class="kf-user" src="../../assets/images/kf-girl.svg" >
                                    <div class="media-body">
                                        <h5>小帅宝</h5>
                                        <p class="mb-0">在线
                                            <el-dropdown>
                                            <span class="el-dropdown-link">
                                                <i class="el-icon-arrow-down el-icon--right"></i>
                                            </span>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item>在线</el-dropdown-item>
                                                <el-dropdown-item>离线</el-dropdown-item>
                                                <el-dropdown-item>退出</el-dropdown-item>
                                            </el-dropdown-menu>
                                            </el-dropdown>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="media">
                                  <el-input
                                    placeholder="请输入账号"
                                    suffix-icon="el-icon-search"
                                    v-model="input1">
                                </el-input>
                            </li>
                        </ul>
                        <div class="chat-user-list">
                            <el-tabs v-model="activeName" @tab-click="handleClick">
                                <el-tab-pane label="当前消息(99+)" name="first">
                                    <ul class="list-unstyled">
                                        <li class="media-active">
                                            <div class="user-detail">
                                                <div class="user-image">
                                                    <i class="arsenal_icon arsenalshouji"></i>
                                                </div>
                                                <div class="media-body">
                                                    <h5>手机用户</h5>
                                                </div>
                                                <el-badge :value="300" :max="99" class="item"></el-badge>
                                            </div>
                                            <div class="message-preview"><span>这里展现最近一次对话内容...</span></div>
                                        </li>
                                        <li class="media">
                                            <div class="user-detail">
                                                <div class="user-image web">
                                                    <i class="arsenal_icon arsenalweb"></i>
                                                </div>
                                                <div class="media-body">
                                                    <h5>电脑用户</h5>
                                                </div>
                                                <el-badge :value="300" :max="99" class="item"></el-badge>
                                            </div>
                                            <div class="message-preview">这里展现最近一次对话内容...</div>
                                        </li>
                                        <li class="media">
                                            <div class="user-detail">
                                                <div class="user-image qichat">
                                                    <i class="arsenal_icon arsenalqiyeweixin"></i>
                                                </div>
                                                <div class="media-body">
                                                    <h5>企业微信用户</h5>
                                                </div>
                                                <el-badge :value="300" :max="99" class="item"></el-badge>
                                            </div>
                                            <div class="message-preview">这里展现最近一次对话内容...</div>
                                        </li>
                                        <li class="media">
                                            <div class="user-detail">
                                                <div class="user-image app">
                                                    <i class="arsenal_icon arsenalapp"></i>
                                                </div>
                                                <div class="media-body">
                                                    <h5>APP用户</h5>
                                                </div>
                                                <el-badge :value="300" :max="99" class="item"></el-badge>
                                            </div>
                                            <div class="message-preview">这里展现最近一次对话内容...</div>
                                        </li>
                                    </ul>
                                </el-tab-pane>
                                <el-tab-pane label="历史消息" name="second">
                                    <ul class="list-unstyled">
                                        <li class="media-active">
                                            <div class="user-detail">
                                                <div class="user-image offline">
                                                    <i class="arsenal_icon arsenalshouji"></i>
                                                </div>
                                                <div class="media-body">
                                                    <h5>手机用户</h5>
                                                </div>
                                                <el-badge :value="300" :max="99" class="item"></el-badge>
                                            </div>
                                            <div class="message-preview"><span>这里展现最近一次对话内容...</span></div>
                                        </li>
                                        <li class="media">
                                            <div class="user-detail">
                                                <div class="user-image offline">
                                                    <i class="arsenal_icon arsenalweb"></i>
                                                </div>
                                                <div class="media-body">
                                                    <h5>电脑用户</h5>
                                                </div>
                                                <el-badge :value="300" :max="99" class="item"></el-badge>
                                            </div>
                                            <div class="message-preview">这里展现最近一次对话内容...</div>
                                        </li>
                                        <li class="media">
                                            <div class="user-detail">
                                                <div class="user-image offline">
                                                    <i class="arsenal_icon arsenalqiyeweixin"></i>
                                                </div>
                                                <div class="media-body">
                                                    <h5>企业微信用户</h5>
                                                </div>
                                                <el-badge :value="300" :max="99" class="item"></el-badge>
                                            </div>
                                            <div class="message-preview">这里展现最近一次对话内容...</div>
                                        </li>
                                        <li class="media">
                                            <div class="user-detail">
                                                <div class="user-image offline">
                                                    <i class="arsenal_icon arsenalapp"></i>
                                                </div>
                                                <div class="media-body">
                                                    <h5>APP用户</h5>
                                                </div>
                                                <el-badge :value="300" :max="99" class="item"></el-badge>
                                            </div>
                                            <div class="message-preview">这里展现最近一次对话内容...</div>
                                        </li>
                                    </ul>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </el-aside>
                <el-container>
                    <el-header>
                        <div class="chat-middle">
                            <div class="chat-message-header">
                                <el-row>
                                    <el-col :span="12">
                                        <div class="grid-content bg-purple">
                                            <div class="user-detail">
                                                <div class="user-image qichat">
                                                        <i class="arsenal_icon arsenalqiyeweixin"></i>
                                                </div>
                                                <div class="media-body">
                                                    <h5>企业微信用户</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="grid-content bg-purple-light operation">
                                            <i class="arsenal_icon arsenalzhuanjie"></i>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </el-header>
                    <el-main>
                        <div class="chat-message-content">
                            <div class="message">
                                <div class="user-detail">
                                    <div class="user-image qichat">
                                            <i class="arsenal_icon arsenalqiyeweixin"></i>
                                    </div>
                                    <div class="media-body">
                                        <p>你刚发消息说签收了，我只收到一箱你刚发消息说签收了，我只收到一箱你刚发消息说签收了，我只收到一箱你刚发消息说签收了，我只收到一箱</p>
                                        <span>18:11:30</span>
                                    </div>
                                </div>
                            </div>
                            <div class="message kefumessage">
                                <div class="user-detail">
                                    <div class="media-body">
                                        <p>你刚发消息说签收了，我只收我只收到一箱你刚发消息说签收了，我只收到一箱</p>
                                        <span>18:11:30</span><span class="botname">IT报修机器人</span>
                                    </div>
                                    <div class="user-image">
                                            <img src="img/kf-girl.dd7b3a33.svg" class="kf-user">
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="message">
                                <div class="user-detail">
                                    <div class="user-image qichat">
                                            <i class="arsenal_icon arsenalqiyeweixin"></i>
                                    </div>
                                    <div class="media-body">
                                        <p>你刚发消息说签收了，我只收到一箱你刚发消息说签收了，我只收到一箱你刚发消息说签收了，我只收到一箱你刚发消息说签收了，我只收到一箱</p>
                                        <span>18:11:30</span>
                                    </div>
                                </div>
                            </div>
                            <div class="message kefumessage">
                                <div class="user-detail">
                                    <div class="media-body">
                                        <p>你刚发消息说签收了，我只收我只收到一箱你刚发消息说签收了，我只收到一箱</p>
                                        <span>18:11:30</span><span class="botname">IT报修机器人</span>
                                    </div>
                                    <div class="user-image">
                                            <img src="img/kf-girl.dd7b3a33.svg" class="kf-user">
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="message">
                                <div class="user-detail">
                                    <div class="user-image qichat">
                                            <i class="arsenal_icon arsenalqiyeweixin"></i>
                                    </div>
                                    <div class="media-body">
                                        <p>一箱你刚</p>
                                        <span>18:11:30</span>
                                    </div>
                                </div>
                            </div>
                            <div class="message kefumessage">
                                <div class="user-detail">
                                    <div class="media-body">
                                        <p>
                                             <video-player
                                                    class="video-player vjs-custom-skin"
                                                    ref="videoPlayer"
                                                    :playsinline="true"
                                                    :options="playerOptions"
                                                    >
                                            </video-player>
                                        </p>
                                        <span>18:11:30</span><span class="botname">IT报修机器人</span>
                                    </div>
                                    <div class="user-image">
                                            <img src="img/kf-girl.dd7b3a33.svg" class="kf-user">
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="message-notice">
                                <p class="notice-content-day">
                                    <span class="line-left"></span>
                                    <span class="line-text">2020-01-06</span>
                                    <span class="line-right"> </span>
                                </p>
                            </div>
                            <div class="message-notice">
                                <p class="notice-content">客户和机器人建立会话 11:15:42</p>
                            </div>
                            <div class="message-notice">
                                <p class="notice-content">客户转人工服务 11:15:45</p>
                            </div>
                            <div class="message-notice">
                                <p class="notice-content">客户和客服 阿毛 的会话已结束 11:16:01 </p>
                            </div>
                            <div class="message">
                                <div class="user-detail">
                                    <div class="user-image qichat">
                                            <i class="arsenal_icon arsenalqiyeweixin"></i>
                                    </div>
                                    <div class="media-body">
                                        <p>你刚发消息说签收了，我只收到一箱你刚发消息说签收了，我只收到一箱你刚发消息说签收了，我只收到一箱你刚发消息说签收了，我只收到一箱</p>
                                        <span>18:11:30</span>
                                    </div>
                                </div>
                            </div>
                            <div class="message kefumessage">
                                <div class="user-detail">
                                    <div class="media-body">
                                        <p><el-image 
                                                style="width: 100px; height: 100px"
                                                :src="url" 
                                                :preview-src-list="srcList">
                                            </el-image>
                                        </p>
                                        <span>18:11:30</span><span class="botname">IT报修机器人</span>
                                    </div>
                                    <div class="user-image">
                                            <img src="img/kf-girl.dd7b3a33.svg" class="kf-user">
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </el-main>
                    <el-footer>
                        <div class="input-componts">
                            <a href="" class="componts-item"><span><i class="arsenal_icon arsenalsmile-beam"></i></span></a>
                            <a href="" class="componts-item"><span><i class="arsenal_icon arsenalimage-solid"></i></span></a>
                            <a href="" class="componts-item"><span><i class="arsenal_icon arsenalfile-upload-solid"></i></span></a>
                            <a href="" class="componts-item"><span><i class="arsenal_icon arsenalvideo-solid"></i></span></a>
                        </div>
                        <div class="input-content">
                            <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="textarea">
                            </el-input>
                            <el-button type="danger">发送</el-button>
                        </div>
                    </el-footer>
                </el-container>
                <el-aside class="chat-right">
                    <el-card class="box-card" shadow="never">
                        <div slot="header" class="clearfix">
                            <span>访客详情</span>
                        </div>
                        <div class="text item">
                            <i class="arsenal_icon arsenaljiqiren"></i><span>优衣库IT报修机器人</span>
                        </div>
                        <div class="text item">
                            <i class="arsenal_icon arsenallaiyuan"></i><span>企业微信</span>
                        </div>
                        <div class="text item">
                            <i class="arsenal_icon arsenalusers-solid"></i><span>优衣库北京新中关店</span>
                        </div>
                        <div class="text item">
                            <i class="arsenal_icon arsenalyuangong"></i><span>店员-王海</span>
                        </div>
                    </el-card>
                </el-aside>
                </el-container>
        </div>
    </div>
</template>
<script>
import 'video.js/dist/video-js.css'
import {videoPlayer} from 'vue-video-player'
export default {
    components: {
            videoPlayer
        },
    data() {
        return {
            activeName: 'first',
            url: 'http://mpimg.cnfol.com/caiji/20190508/b4ab522c43da89830d3605ee8c26a742.jpg',
            srcList: [
            'http://mpimg.cnfol.com/caiji/20190508/b4ab522c43da89830d3605ee8c26a742.jpg',
            'http://mpimg.cnfol.com/caiji/20190508/b4ab522c43da89830d3605ee8c26a742.jpg'
            ],
            playerOptions: {
                playbackRates: [0.7, 1.0, 1.5, 2.0], 
                autoplay: false, 
                muted: false, 
                loop: false, 
                preload: "auto",
                language: "zh-CN",
                aspectRatio: "16:9",
                fluid: true,
                sources: [
                {
                    src: "https://qiniu-xpc10.xpccdn.com/5cbb6746e067a.mp4", 
                    type: "video/mp4" // 类型
                },
                {
                    src: "https://qiniu-xpc10.xpccdn.com/5cbb6746e067a.mp4",
                    type: "video/webm"
                }
                ],
                poster: "src/images/logo.png", //你的封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试",
                controlBar: {
                timeDivider: true,
                durationDisplay: true,
                remainingTimeDisplay: false,
                fullscreenToggle: true
                }
            }
        };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
};
</script>
<style lang="less">
@import "./../../assets/less/instantMessage/instantMessage.less";
</style>